<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title
        >Payout - {{ form.payoutKey }}
        <v-chip class="ml-1 mr-1" v-if="!loading && form.paid" color="success">Paid</v-chip>
        <v-chip class="ml-1 mr-1" v-else-if="!loading && !form.paid" color="warning">Unpaid</v-chip>

        <v-chip class="ml-1 mr-1" v-if="!loading && selectedApproval && !selectedApproval.approved" color="warning"
          >Under Approval</v-chip
        >
        <v-chip class="ml-1 mr-1" v-else-if="!loading && selectedApproval && selectedApproval.approved" color="success"
          >Approved</v-chip
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('APPROVAL_CREATE') && form.id && !loading && !selectedApproval">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" @click="onAssignApproval()" v-bind="attrs" v-on="on">
            <v-icon>mdi-clipboard-check</v-icon> {{ $i18n.translate("Assign") }} {{ $i18n.translate("Approval") }}
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Assign") }} {{ $i18n.translate("Approval") }}</span>
      </v-tooltip>
      <v-tooltip
        bottom
        v-if="$privilege.hasPrivilege('PAYOUT_CREATE') && !loading && !isEditing && form.id && !form.paid"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" @click="onPay" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-cash</v-icon> {{ $i18n.translate("Pay Payout") }}
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Pay Payout") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isEditing && (tab == 0 || tab == 2)">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('PAYOUT_UPDATE') && !isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>

      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>
            Payout Details
          </v-tab>
          <v-tab>
            Payout Log
          </v-tab>
          <v-tab>
            Custom Fields
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <AlertDisplay ref="alertRef" durationOption="long" />

      <div class="text-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-form :value="valid" @submit.prevent="onSubmit" v-if="!loading" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <KeyField
                    name="payoutKey"
                    label="Key"
                    id="payoutKey"
                    v-model="form.payoutKey"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'payoutKey')"
                    @input="$error.clearValidationError(errors, 'payoutKey')"
                  />
                  <v-text-field
                    name="payoutAmount"
                    label="Payout Amount"
                    id="payoutAmount"
                    type="number"
                    v-model="form.payoutAmount"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'payoutAmount')"
                    @input="$error.clearValidationError(errors, 'payoutAmount')"
                  />

                  <v-text-field
                    v-if="form.payoutStrategyType"
                    name="payoutStrategyType"
                    label="Payout Strategy"
                    readonly
                    id="payoutStrategyType"
                    :value="form.payoutStrategyType.description"
                  />
                  <v-text-field
                    name="duplicationKey"
                    label="Duplication Key"
                    readonly
                    id="duplicationKey"
                    :value="form.duplicationKey"
                  />
                  <v-text-field
                    name="payoutMessage"
                    label="Payout Message"
                    readonly
                    id="duplicationKey"
                    :value="form.payoutMessage"
                  />
                  <v-select
                    :items="[
                      { text: 'Use Award Vehicle Settings', value: null },
                      { text: 'Yes (Override Award Vehicle Settings)', value: true },
                      { text: 'No (Override Award Vehicle Settings)', value: false }
                    ]"
                    v-model="form.taxable"
                    :label="$i18n.translate('Taxable')"
                    readonly
                  ></v-select>
                  <TaxableDeterminationTypeField
                    name="taxableDetermination"
                    label="Taxable Determination"
                    id="taxableDetermination"
                    v-model="form.taxableDetermination"
                    readonly
                  />
                  <DateTimePickerField
                    v-model="form.communicatedToClientDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Communicated To Client Date')"
                    defaultTime="00:00"
                  />
                  <DateTimePickerField
                    v-model="form.effectiveDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Effective Date')"
                    defaultTime="00:00"
                  />
                  <DateTimePickerField
                    v-model="form.expirationDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Expiration Date')"
                    defaultTime="23:59"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-if="selectedParticipant"
                    :label="$i18n.translate('Participant')"
                    :value="selectedParticipant.fullName"
                    readonly
                  >
                    <v-tooltip bottom slot="append" v-if="$privilege.hasPrivilege('PARTICIPANT_READ') && !isNew">
                      <template v-slot:activator="{ on: on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          :to="{
                            name: 'participant',
                            params: { id: selectedParticipant.id }
                          }"
                        >
                          <v-icon color="primary">mdi-open-in-new</v-icon>
                        </v-btn>
                      </template>
                      <span>Go to {{ $i18n.translate("Participant") }}</span>
                    </v-tooltip>
                  </v-text-field>

                  <v-text-field
                    v-if="selectedOrganization"
                    :label="$i18n.translate('Organization')"
                    :value="selectedOrganization.name"
                    readonly
                  >
                    <v-tooltip bottom slot="append" v-if="$privilege.hasPrivilege('ORGANIZATION_READ') && !isNew">
                      <template v-slot:activator="{ on: on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          :to="{
                            name: 'organization',
                            params: { id: selectedOrganization.id }
                          }"
                        >
                          <v-icon color="primary">mdi-open-in-new</v-icon>
                        </v-btn>
                      </template>
                      <span>Go to {{ $i18n.translate("Organization") }}</span>
                    </v-tooltip>
                  </v-text-field>

                  <v-text-field
                    v-if="selectedPromotion"
                    :label="$i18n.translate('Promotion')"
                    :value="selectedPromotion.promotionKey + ' - ' + selectedPromotion.name"
                    readonly
                  >
                    <v-tooltip bottom slot="append" v-if="$privilege.hasPrivilege('PROMOTION_READ') && !isNew">
                      <template v-slot:activator="{ on: on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          :to="{
                            name: 'promotion',
                            params: { id: selectedPromotion.id }
                          }"
                        >
                          <v-icon color="primary">mdi-open-in-new</v-icon>
                        </v-btn>
                      </template>
                      <span>Go to {{ $i18n.translate("Promotion") }}</span>
                    </v-tooltip>
                  </v-text-field>
                  <v-text-field
                    v-if="selectedSaleProduct"
                    :label="$i18n.translate('Sale Product')"
                    :value="
                      (selectedSaleProduct_Product
                        ? selectedSaleProduct_Product.productKey + ' - ' + selectedSaleProduct_Product.name + ' - #'
                        : '') + selectedSaleProduct.serialNumber
                    "
                    readonly
                  >
                    <v-tooltip bottom slot="append" v-if="$privilege.hasPrivilege('PRODUCT_READ') && !isNew">
                      <template v-slot:activator="{ on: on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          :to="{
                            name: 'sale',
                            params: { id: selectedSaleProduct.saleId }
                          }"
                        >
                          <v-icon color="primary">mdi-open-in-new</v-icon>
                        </v-btn>
                      </template>
                      <span>Go to {{ $i18n.translate("Sale") }}</span>
                    </v-tooltip>
                  </v-text-field>
                  <v-text-field
                    v-if="selectedClaimProduct"
                    :label="$i18n.translate('Claim Product')"
                    :value="
                      (selectedClaimProduct_Product
                        ? selectedClaimProduct_Product.productKey + ' - ' + selectedClaimProduct_Product.name + ' - #'
                        : '') + selectedClaimProduct.serialNumber
                    "
                    readonly
                  >
                    <v-tooltip bottom slot="append" v-if="$privilege.hasPrivilege('CLAIM_READ') && !isNew">
                      <template v-slot:activator="{ on: on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          :to="{
                            name: 'claim',
                            params: { id: selectedClaimProduct.claim.id }
                          }"
                        >
                          <v-icon color="primary">mdi-open-in-new</v-icon>
                        </v-btn>
                      </template>
                      <span>Go to {{ $i18n.translate("Claim") }}</span>
                    </v-tooltip>
                  </v-text-field>
                  <v-text-field
                    v-if="selectedAwardVehicle"
                    :label="$i18n.translate('Award Vehicle')"
                    :value="selectedAwardVehicle.name"
                    readonly
                  >
                    <v-tooltip bottom slot="append" v-if="$privilege.hasPrivilege('AWARD_VEHICLE_READ') && !isNew">
                      <template v-slot:activator="{ on: on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          :to="{
                            name: 'awardVehicle',
                            params: { id: selectedAwardVehicle.id }
                          }"
                        >
                          <v-icon color="primary">mdi-open-in-new</v-icon>
                        </v-btn>
                      </template>
                      <span>Go to {{ $i18n.translate("Award Vehicle") }}</span>
                    </v-tooltip>
                  </v-text-field>

                  <v-text-field
                    v-if="selectedApproval"
                    :label="$i18n.translate('Approval')"
                    :value="
                      selectedApproval.approvalKey +
                        ' - ' +
                        (selectedApproval_ApprovalStage ? selectedApproval_ApprovalStage.name : '')
                    "
                    readonly
                  >
                    <v-tooltip bottom slot="append" v-if="$privilege.hasPrivilege('APPROVAL_READ') && !isNew">
                      <template v-slot:activator="{ on: on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          :to="{
                            name: 'approval',
                            params: { id: selectedApproval.id }
                          }"
                        >
                          <v-icon color="primary">mdi-open-in-new</v-icon>
                        </v-btn>
                      </template>
                      <span>Go to {{ $i18n.translate("Approval") }}</span>
                    </v-tooltip>
                  </v-text-field>

                  <v-textarea
                    v-if="selectedBankTransaction"
                    :label="$i18n.translate('Bank Transaction')"
                    :value="selectedBankTransaction.bankTransactionKey + ' - ' + selectedBankTransaction.message"
                    readonly
                  >
                    <v-tooltip bottom slot="append" v-if="!isNew">
                      <template v-slot:activator="{ on: on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          :to="{
                            name: 'bankTransaction',
                            params: { id: selectedBankTransaction.id }
                          }"
                        >
                          <v-icon color="primary">mdi-open-in-new</v-icon>
                        </v-btn>
                      </template>
                      <span>Go to {{ $i18n.translate("Bank Transaction") }}</span>
                    </v-tooltip>
                  </v-textarea>
                </v-col>
                <v-col cols="4">
                  <h2>Information</h2>
                  <ul>
                    <li>
                      An <b>Payout</b> defines how an Payout will operate. It will include payout key prefix and payout
                      stages.
                    </li>
                    <li>
                      The <b>Auto Assign Participant</b> will be automatically assigned when the Payout is initiated. If
                      this is left blank, then there will be no assignment yet.
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <pre>{{ form.payoutLog }}</pre>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <template v-for="(customField, index) in selectedProgram.payoutCustomFields">
                    <CustomField
                      :key="customField.id"
                      v-model="form[`customFieldValue${index + 1}`]"
                      :disabled="!isEditing"
                      v-if="customField"
                      :customField="customField"
                    />
                  </template>
                </v-col>
                <v-col cols="6">
                  <h2 class="mb-2">Payout Custom Fields</h2>
                  <ol>
                    <li>
                      Configure each custom field at the Program level.
                    </li>
                  </ol>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../display/ApiError.vue";
import CustomField from "../../fields/CustomField.vue";
import DateTimePickerField from "../../fields/DateTimePickerField.vue";
import KeyField from "../../fields/KeyField.vue";
import TaxableDeterminationTypeField from "../../fields/TaxableDeterminationTypeField.vue";
import AlertDisplay from "../../display/AlertDisplay.vue";

export default {
  components: { ApiError, KeyField, DateTimePickerField, CustomField, TaxableDeterminationTypeField, AlertDisplay },
  name: "Payout",
  metaInfo: {
    title: "Payout"
  },

  data: function() {
    return {
      payoutId: 0,
      tab: null,
      valid: false,
      isNew: false,
      isEditing: false,
      loading: false,
      form: {},
      payoutStages: {
        existing: [],
        deleted: []
      },
      errors: {},
      rules: {
        name: [v => !!v || "Name is required"]
      },

      selectedAwardVehicle: null,
      selectedParticipant: null,
      selectedOrganization: null,
      selectedPromotion: null,
      selectedClaimProduct: null,
      selectedClaimProduct_Product: null,
      selectedSaleProduct: null,
      selectedSaleProduct_Product: null,
      selectedApprovalType: null,
      selectedApproval: null,
      selectedApproval_ApprovalStage: null,
      selectedBankTransaction: null
    };
  },
  methods: {
    onPay() {
      this.loading = true;
      this.errors = {};
      return this.$api
        .post("/api/payouts/" + this.$route.params.id + "/payPayouts", {})
        .then(({ data }) => {
          this.form = data;
          if (this.form?.errorMessage) {
            this.$refs?.alertRef?.info(this.form.errorMessage);
          }
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formateFormValue(form) {
      for (const key in form) {
        if (key.startsWith("customFieldValue") && Array.isArray(form[key])) {
          form[key] = form[key].join("|");
        }
      }
      return form;
    },
    onSubmit() {
      this.loading = true;
      this.errors = {};
      let postForm = Object.assign({}, this.formateFormValue(this.form));

      let serviceCall = null;

      if (this.isNew) {
        serviceCall = this.$api.post("/api/payouts", postForm);
      } else {
        serviceCall = this.$api.patch("/api/payouts/" + this.$route.params.id, postForm);
      }
      serviceCall
        .then(({ data }) => {
          this.form = data;
          this.isEditing = false;
          if (this.isNew) {
            this.isNew = false;
            this.$route.params.id = data.id;
            this.payoutId = data.id;
          }
          this.fetchData();
        })
        .catch(error => {
          this.isEditing = true;
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    fetchData() {
      if (!this.isNew) {
        this.loading = true;
        return this.$api
          .get("/api/payouts/" + this.$route.params.id)
          .then(({ data }) => {
            this.form = data;
            this.breadcrumb();
            return Promise.all([
              this.$api.getRelatedObject("awardVehicle", data).then(({ data }) => {
                this.selectedAwardVehicle = data;
              }),
              this.$api.getRelatedObject("participant", data).then(({ data }) => {
                this.selectedParticipant = data;
              }),
              this.$api.getRelatedObject("organization", data).then(({ data }) => {
                this.selectedOrganization = data;
              }),
              this.$api.getRelatedObject("promotion", data).then(({ data }) => {
                this.selectedPromotion = data;
              }),
              this.$api
                .getRelatedObject("claimProduct", data)
                .then(({ data }) => {
                  this.selectedClaimProduct = data;
                  return Promise.all([
                    this.$api.getRelatedObject("product", data).then(({ data }) => {
                      this.selectedClaimProduct_Product = data;
                    })
                  ]);
                })
                .catch(() => {
                  console.log("Claim Product not found");
                }),
              this.$api
                .getRelatedObject("saleProduct", data)
                .then(({ data }) => {
                  this.selectedSaleProduct = data;
                  return Promise.all([
                    this.$api.getRelatedObject("product", data).then(({ data }) => {
                      this.selectedSaleProduct_Product = data;
                    })
                  ]);
                })
                .catch(() => {
                  console.log("Sale Product not found");
                }),
              this.$api
                .getRelatedObject("approvalType", data)
                .then(({ data }) => {
                  this.selectedApprovalType = data;
                })
                .catch(() => {
                  console.log("Approval Type not found");
                }),
              this.$api
                .getRelatedObject("approval", data)
                .then(({ data }) => {
                  this.selectedApproval = data;
                  return Promise.all([
                    this.$api.getRelatedObject("approvalStage", data).then(({ data }) => {
                      this.selectedApproval_ApprovalStage = data;
                    })
                  ]);
                })
                .catch(() => {
                  console.log("Approval not found");
                }),
              this.$api
                .getRelatedObject("bankTransaction", data)
                .then(({ data }) => {
                  this.selectedBankTransaction = data;
                })
                .catch(() => {
                  console.log("Bank Transaction not found");
                })
            ]);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        return Promise.resolve();
      }
    },
    onAssignApproval() {
      this.$router.push({ name: "approval", params: { id: 0 }, query: { payoutId: this.form.id } });
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Payouts",
            to: { name: "payouts" },
            exact: true
          },
          { text: "New Payout" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: "Payouts",
            to: { name: "payouts" },
            exact: true
          },
          { text: this.form.name }
        ]);
      }
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    title() {
      return "Payout - " + this.form.name;
    }
  },

  mounted() {
    this.payoutId = parseInt(this.$route.params.id);
    if (this.$route.params.id == 0) {
      this.isNew = true;
      this.isEditing = true;
      this.form.program = this.$api.getSelfUrl("programs", this.selectedProgram);
      this.breadcrumb();
    }

    this.fetchData();
  }
};
</script>
