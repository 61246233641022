<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    item-value="name"
    :item-text="item => `${item.description}`"
    clearable
    return-object
    :loading="loading"
  >
  </v-select>
</template>

<script>
import ApiService from "@/gapp-components/services/api.service";

export default {
  name: "TaxableDeterminationTypeField",
  props: {
    value: Object
  },
  data: () => ({
    loading: false,
    items: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },

    fetchData() {
      this.loading = true;
      ApiService.get("/api/types/taxableDeterminations")
        .then(({ data }) => {
          this.items = data;
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
